import { useFilePicker } from "use-file-picker";
import React, { useState } from 'react';
import './Products.scss';


class ProductDatabase extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            //productsDisplayed: [],
            isLoading: false,
            isLoadingMore: false

        };
    }

	componentDidMount = () => {

		//get catagories
		fetch('https://ylo.io/api/admin/products', {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
		})
		.then((response) => response.json())
		.then((responseJson) => {
			return responseJson;
		})
		.then((_json) => {
			this.setState(prevState => ({
				isLoading: false,
				products: [...prevState.products, ..._json],
				//productsDisplayed: [...prevState.productsDisplayed, ..._json],
				isLoadingMore: false
			}));

			console.log(this.state.products[0]);

		})
		.catch((error) => {
			console.log("[ERROR] LOADING CATEGORY LIST!");
			console.log(error);
		});




    }


    RenderProductList = () => {
        return this.state.products.map((product, i) => (
			<div className="ad-list-segment" key={product.id.toString()}>
				<div className="list-image"><img src={product.attachments[0].source} /></div>
                <div className="ad-list-col" style={{width: "40%"}}>
                    {product.name}
                    <div className="list-item-id">ID {product.id}</div>
                </div>
				<div className="ad-list-col">{product.category_id}</div>
				<div className="ad-list-col" style={{ width: "6%" }}>Yes</div>
				<div className="ad-list-col" style={{ width: "15%" }}>{product.updated_at}</div>
				<div className="ad-list-col" style={{width: 70}}>
					<button className="button-basic" style={{ width: "100%", height: "34px", right: "0px" }} onClick={() => { this.props.history.push('/edit-product/' + product.id) }}> Edit </button>
                </div>
            </div>
        ));
    }


    render() {
        return (
            <div className="app-base">

				{/* TOP BAR */}

				<h1 className="page-heading">All Products</h1>

                <div className="ad-list-top">
					<div className="list-image"></div>
                    <div className="ad-list-col" style={{ width: "40%" }}>Name</div>
					<div className="ad-list-col"> Category </div>
					<div className="ad-list-col" style={{ width: "6%" }}>External</div>
					<div className="ad-list-col" style={{ width: "15%" }}>Last Updated</div>
					<div className="ad-list-col" style={{width: 70}}></div>
                </div>

                {this.state.products.length === 0 &&
                    <div className="product-nolisting">
                        <div className="ad-list-segment">
                            No Products!
                        </div>
                    </div>
                }

				{this.RenderProductList()}

				{ /* page top bar */}
				<div className="global-pagebar">
					<div></div>
					<button className="button-basic" style={{ height: 34 }} onClick={() => { this.props.history.push('/create-product') }}>
						{/*<div className="ad-list-add-button"> <div className="cross" /> </div>*/}
						Add Product
					</button>
				</div>

            </div>
        )
    }


}


export default ProductDatabase;
