import React, { useState } from 'react';


class AdList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ads: [],
            //adsDisplayed: [],
            isLoading: false,
			isLoadingMore: false
        };
    }

    componentDidMount = () => {
        //this.GetProducts();
		//get catagories
		/*
		fetch('https://ylo.io/api/admin/products', {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
		})
		.then((response) => response.json())
		.then((responseJson) => {
			return responseJson;
		})
		.then((_json) => {
			this.setState(prevState => ({
				isLoading: false,
				ads: [...prevState.ads, ..._json],
				isLoadingMore: false
			}));

			console.log(this.state.products[0]);

		})
		.catch((error) => {
			console.log("[ERROR] LOADING CATEGORY LIST!");
			console.log(error);
		});
		*/
    }


    RenderAdList = () => {
        return this.state.ads.map((ad, i) => (
            <div className="ad-list-segment">
                <div className="ad-list-col ad-list-col-small"> {ad.id} </div>
                <div className="ad-list-col ad-list-col-large"> { ad.name } </div>
                <div className="ad-list-col"> {ad.id} </div>
                <div className="ad-list-col"> {ad.id} </div>
				<div className="ad-list-col" style={{ width: "15%" }}> uihuhiuhu </div>
                <div className="ad-list-col">
                    <button className="button-basic" style={{ width: "60%", height: "32px", borderTop: "-2px" }}> Edit </button>
                </div>
            </div>
        ));
    }


    render() {
        return (
            <div className="app-base">
                <h3> All Ads </h3>
                <div className="ad-list-top">
                    <div className="ad-list-col ad-list-col-small"> ID </div>
                    <div className="ad-list-col ad-list-col-large"> Title </div>
                    <div className="ad-list-col"> Category </div>
                    <div className="ad-list-col"> Acc. ID </div>
					<div className="ad-list-col" style={{ width: "15%" }}> Last Updated </div>
                    <div className="ad-list-col">  </div>
                </div>

                {this.state.ads.length === 0 &&
                    <div className="product-nolisting">
                        <div className="ad-list-segment">
                            No Ads!
                        </div>
                    </div>
                }

				{this.RenderAdList()}

				{ /* page top bar */}
				<div className="global-pagebar">
					<div className="filter-button"> ... Filter </div>
					<button className="button-basic" style={{ width: "90px", height: "90%" }}>
						{/*<div className="ad-list-add-button"> <div className="cross" /> </div>*/}
						Add
					</button>
				</div>

            </div>
        )
    }
}


export default AdList;