import './App.scss';
import React, { useState } from 'react';
import { useFilePicker } from "use-file-picker";
import ProductDatabase from './lists/Products';
import AdComposer from './editing/AdComposer';
import ProductComposer from './editing/ProductComposer';
import AdList from './lists/Ads';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function App() {

    const [selectedDropDown, setDropDown] = useState(-1);
    const [searchInput, SetSearchInput] = useState("");


	//opens/closes whatever sidemenu dropdown you're clicking, closes any other open dropdown
    function ToggleDropDown(index) {
        if (selectedDropDown != index) {
            setDropDown(index);
        } else {
            setDropDown(-1);
        }
	}

	function HandleGlobalSearchChange(event) {
		SetSearchInput(event.target.value);
	}


    return (
		<Router>

			{ /* GLOBAL SIDE BAR */ }
			<div className="sidebar">
    			<div className="sidebar-content">

    				<div className="sidebar-logo">
    					<img src="/ylo_logo_white.png"/>
    				</div>

    				<div className="button-sidebar">Pages</div>
    				<div className="button-sidebar">Posts</div>

    				<div className={selectedDropDown === 1 ? "button-sidebar button-sidebar--selected" : "button-sidebar"} onClick={() => ToggleDropDown(1)}>Products</div>
    				{selectedDropDown === 1 &&
    					<div style={{ display: "flex", flexDirection: "column" }}>
    						<Link className="button-sidebar-inline" to="/products">All Products</Link>
    						<Link className="button-sidebar-inline" to="/create-product">Create Product</Link>
    					</div>
    				}

    				{/* ADVERTS */}
    				<div className={selectedDropDown === 0 ? "button-sidebar button-sidebar--selected" : "button-sidebar"} onClick={() => ToggleDropDown(0)}>Advertisements</div>
    				{selectedDropDown === 0 &&
    					<div style={{ display: "flex", flexDirection: "column" }}>
    						<Link className="button-sidebar-inline" to="/ad-list">All Ads</Link>
    						<Link className="button-sidebar-inline" to="/ad-composer">Create Ad</Link>
    					</div>
    				}
    			</div>
			</div>

			<Switch>
                <Route path="/edit-product/:productID/:variationID" component={ProductComposer}/>
                <Route path="/edit-product/:productID" component={ProductComposer}/>
                <Route path="/create-product" component={ProductComposer}/>
				<Route path="/ad-composer" component={AdComposer}/>
				<Route path="/ad-list" component={AdList} />
                <Route path="/products" component={ProductDatabase}/>
				<Route path="/" exact component={ProductDatabase}/>
			</Switch>


			{/* GLOBAL SEARCH BAR */}
			<div className= "global-search">
				{/* <input className="global-search-inputbox" type="text" value={searchInput} onChange={HandleGlobalSearchChange} />
				<div className= "global-search-button"> <div className = "shape-magnifying-glass"/> </div>*/}
			</div>

        </Router>
    );
}

export default App;
