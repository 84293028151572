import React, { useState } from 'react';
import './../App.scss';
import './AdComposer.scss';
import { ChromePicker } from 'react-color';
import FilePicker from './../FilePicker.js';


class AdComposer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
			adBgColour: "#ffffff",
			adTextColour: "#000000",
			adTitle: "Ad",
			adDescription: "Desc",
			adLink: "No Link",
			adImage: ""
        };
	}


	HandleEventChange = (stateKey, event) => {
		this.setState({ [stateKey]: event.target.value });
	}


	HandleAddImage = (data, event) => {
		this.setState({ adImage: data[0].content });
		console.log(data);
    }



	render() {
		return (
			<div>
				<div className="app-base">				
					<h3> Create Ad </h3>
					<div className= "order-row">
					{ /*LEFT SIDE*/ }
						<div className="leftSide" style={{ width: "50%"  }}>
								
							Title
							<input className="input-box-border input-box" type="text" onChange={(e) => this.HandleEventChange("adTitle", e)} />
							Description
							<textarea className="input-box-border input-box input-box--textarea" type="textarea" onChange={(e) => this.HandleEventChange("adDescription", e)} />

								
							<div className="order-row">
								<div style={{marginRight: "2%"}}>
									BG Colour
									<input className="input-box-border input-box" type="text" value={this.state.adBgColour} onChange={(e) => this.HandleEventChange("adBgColour", e)} />
								</div>
								<div style={{ marginLeft: "2%" }}>
									Text Colour
									<input className="input-box-border input-box" type="text" value={this.state.adTextColour} onChange={(e) => this.HandleEventChange("adTextColour", e)} />
								</div>
							</div>

							<br />
								Image or Video
								<div className="input-box-border order-row" style={{alignItems: "center"}}>
								<div className="button-basic" style={{ width: "20%", height: "40px", marginRight: "2%", display: "flex", justifyContent: "center", alignItems: "center" }}>
										Browse
										<FilePicker processFiles={this.HandleAddImage}> </FilePicker>		
									</div>

									<input className="input-box" type="text" value={this.state.adImage.path} onChange={(e) => this.HandleEventChange("adImage", e)} />
								</div>
									
								Link
								<div className="input-box-border order-row">
									<input className="input-box" type="text" value={this.state.adLink} onChange={(e) => this.HandleEventChange("adLink", e)}  />
								</div>

							<br />
							<button className="button-basic" style={{ width: "40%", height: "50px" }}> Save </button>
							<br />
							<br />
							<br />
						</div>

						{ /*RIGHT SIDE*/}
						<div className="adPreview" style={{ }}>
							Preview 
							<div className="preview-box" style={{ backgroundColor: this.state.adBgColour }}>
								<img className="preview-image" src={this.state.adImage} />
									<h3 style={{color: this.state.adTextColour, paddingLeft: "3%"}}> {this.state.adTitle} </h3>
								<div style={{ color: this.state.adTextColour, paddingLeft: "3%" }}> {this.state.adDescription} </div>
							</div>
						</div>
					</div>

				</div>

				{ /* page top bar */}
				<div className="global-pagebar">
					<div> <u> Save Draft </u> </div>
					<button className="button-basic" style={{ width: "90px", height: "90%" }}> Publish </button>
				</div>
			</div>
		)
	}
}


/*
class ColourPicker extends React.Component {

	handleChange = (colour, event) => {
		this.setState({ background: color.hex });
    }


	render() {
		return <ChromePicker onChangeComplete={this.handleChange} />;
	}
}*/








export default AdComposer;