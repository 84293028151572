import { useFilePicker } from 'use-file-picker';
import React, { useEffect } from 'react';
import './App.scss';

export default function FilePicker(props) {
    const [openFileSelector, { filesContent, loading, errors, plainFiles, clear }] = useFilePicker({
        multiple: false,
        readAs: 'DataURL', // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
        accept: 'image/*',
        // readFilesContent: false, // ignores file content
        //maxFileSize: 50,
    });

	useEffect(() => {
		//console.log(plainFiles);
		if (plainFiles.length > 0) {
			props.processFiles(filesContent, plainFiles);
		}
	}, [plainFiles]);

  if (errors.length) {
    return (
      <div>
        <button onClick={() => openFileSelector()}>Something went wrong, retry! </button>
        {errors[0].fileSizeTooSmall && 'File size is too small!'}
        {errors[0].fileSizeToolarge && 'File size is too large!'}
        {errors[0].readerError && 'Problem occured while reading file!'}
        {errors[0].maxLimitExceeded && 'Too many files'}
        {errors[0].minLimitNotReached && 'Not enought files'}
      </div>
    );
  }

  if (loading) {
    return <div>Loading...</div>;
  }

return (

	<div style={{ width: "100%", height: "100%", position: "absolute" }} onClick={() => openFileSelector()}>
		
	{/*
      <div style={{width: "20%"}}>
          <button className="button-basic" style={{ width: "100%", height: "110%" }} onClick={() => openFileSelector()}>Browse</button>
          {/*
        <div>
          {plainFiles.map(file => {
            return (
              <span className="file-name" key={file.name}>{file.name}</span>
            )
          })}
          </div>
          
    </div >
	*/}
	</div>
  );
}
