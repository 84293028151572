import React, { useState } from 'react';
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import './ProductComposer.scss';
import FilePicker from './../FilePicker.js';
import { flushSync } from 'react-dom';
import { Link } from "react-router-dom";


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SELECTION BOX STYLING
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

var categories = []

const productTags = [
	{ value: 'Category Page', label: "Category Page" },
	{ value: 'Latest Deals', label: "Latest Deals" },
	{ value: 'Shoes', label: "Shoes" },
	{ value: ':)', label: ":)" },
	{ value: 'Buy Two, Get Two', label: "Buy Two, Get Two" },
	{ value: 'Funny', label: "Funny" },
	{ value: 'Endorsed by Bill Paxton', label: "Endorsed by Bill Paxton" },
]

const productState = [
	{ value: 1, label: "Published", colour: '#FFFFFF' },
	{ value: 0, label: "Draft", colour: '#FFFFFF'  },
	{ value: -1, label: "Remove", colour: '#FFBAAA'  },
]

const ddProductVariationState = [
	{ value: 1, label: "Active", colour: '#00FF00' },
	{ value: 0, label: "Inactive", colour: '#FF6600' },
]

const dealStates = [
	{ value: 1, label: "Active", colour: '#00FF00' },
	{ value: 0, label: "Inactive", colour: '#FF6600' },
	{ value: -1, label: "Remove", colour: '#FF0000' },
]

//TEMP? to be replaced with system that gets these from web?
const ddProductAttributeKeys = [
	{ value: "Colour", label: "Colour" },
	{ value: "GTIN", label: "GTIN" },
];

const dropdownStyle = {
	menu: (provided, state) => ({
		...provided,
		paddingLeft: "4px",
		paddingRight: "4px",
		borderTopLeftRadius: "0",
		borderTopRightRadius: "0",
		borderBottomLeftRadius: "2px",
		borderBottomRightRadius: "2px",
		marginTop: "1px",
		fontSize: "15px",
	}),
	option: (provided, state) => ({
		...provided,
		borderBottom: '1px solid #eee',
		//backgroundColor: state.isSelected ? 'black' : 'white',
		color: state.isFocused ? 'white' : '#121212',
		backgroundColor: state.isFocused ? '#121212' : state.data.colour,
		cursor: 'pointer'
		//padding: 20,
	}),
	placeholder: (provided, state) => ({
		...provided,
		color: '#121212',
		backgroundColor: 'transparent',
	}),
	container: (provided, state) => ({
		...provided,
		backgroundColor: state.isFocused ? 'white' : 'rgba(0,0,0,0.0)',
		borderTopLeftRadius: "10px",
		borderTopRightRadius: "10px",
		border: state.isFocused ? "1px solid #dedede" : "none",
		borderBottom: state.isFocused ? "1px solid #dedede" : "1px solid #121212",
		boxShadow: state.isFocused ? "0px -1px 2px 2px rgba(0,0,10,0.05)" : "none",
		transition: "0.3s",
		cursor: 'pointer'
	}),
	control: () => ({
		// none of react-select's styles are passed to <Control />
		display: 'flex',
		flexDirection: 'row',
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';

		return { ...provided, opacity, transition };
	}
}


// draws the coloured round dot for dropdowns that use it
const dealStateDot = (color = '#ccc') => ({
	alignItems: 'center',
	display: 'flex',

	':before': {
		backgroundColor: color,
		borderRadius: 10,
		content: '" "',
		display: 'block',
		marginRight: 8,
		height: 20,
		width: 20,
	},
});


const ddDealStateStyle = {
	menu: (provided, state) => ({
		...provided,
		padding: "5px",
		borderTopLeftRadius: "0",
		borderTopRightRadius: "10px",
		borderBottomLeftRadius: "10px",
		borderBottomRightRadius: "10px",
		marginTop: "1px",
		width: "160%",
	}),
	option: (provided, state) => ({
		...provided,
		borderBottom: "1px solid #eeeeee",
		//backgroundColor: state.isSelected ? 'black' : 'white',
		color: state.isFocused ? 'white' : 'black',
		backgroundColor: state.isFocused ? 'black' : 'white',
		//padding: 20,
		cursor: 'pointer',
		...dealStateDot(state.data.colour)

	}),
	container: (provided, state) => ({
		...provided,
		backgroundColor: state.isFocused ? 'white' : 'rgba(0,0,0,0.0)',
		//borderTopLeftRadius: "10px",
		//borderTopRightRadius: "10px",
		border: state.isFocused ? "1px solid #dedede" : "none",
		boxShadow: state.isFocused ? "0px -1px 2px 2px rgba(0,0,10,0.05)" : "none",
		transition: "0.3s",
		cursor: 'pointer',
	}),
	control: () => ({
		// none of react-select's styles are passed to <Control />
		display: 'flex',
		flexDirection: 'row',
	}),

	indicatorSeparator: () => ({}),
	indicatorsContainer: () => ({}),
	dropdownIndicator: () => ({ marginTop: '8px', }),
	downChevron: () => ({}),

	placeholder: (styles) => ({ ...styles, ...dealStateDot() }),

	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';
		//console.log(provided);
		return {
			...provided, opacity, transition, ...dealStateDot(state.data.colour)
		};
	}
}


const ddStyleVariationState = {
	menu: (provided, state) => ({
		...provided,
		padding: "5px",
		borderTopLeftRadius: "0",
		borderTopRightRadius: "10px",
		borderBottomLeftRadius: "10px",
		borderBottomRightRadius: "10px",
		marginTop: "1px",
		width: "110%",
	}),
	option: (provided, state) => ({
		...provided,
		borderBottom: "1px solid #eeeeee",
		//backgroundColor: state.isSelected ? 'black' : 'white',
		color: state.isFocused ? 'white' : 'black',
		backgroundColor: state.isFocused ? 'black' : 'white',
		//padding: 20,

		cursor: 'pointer',
		...dealStateDot(state.data.colour)

	}),
	container: (provided, state) => ({
		...provided,
		backgroundColor: state.isFocused ? 'white' : 'rgba(0,0,0,0.0)',
		borderTopLeftRadius: "5px",
		borderTopRightRadius: "5px",
		border: state.isFocused ? "1px solid #dedede" : "none",
		borderBottom: state.isFocused ? "1px solid #dedede" : "1px solid #121212",
		boxShadow: state.isFocused ? "0px -1px 2px 2px rgba(0,0,10,0.05)" : "none",
		transition: "0.3s",
		cursor: 'pointer'
	}),
	control: () => ({
		// none of react-select's styles are passed to <Control />
		display: 'flex',
		flexDirection: 'row',
	}),

	indicatorSeparator: () => ({}),
	indicatorsContainer: () => ({}),
	dropdownIndicator: () => ({ marginTop: '8px', }),
	downChevron: () => ({}),

	placeholder: (styles) => ({ ...styles, }),

	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';

		return {
			...provided,
			opacity,
			transition,
			//...dealStateDot(state.data.colour)
			backgroundColor: state.data.colour,
			borderRadius: '15px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '26px',
			color: 'white',
			fontWeight: '500',
			maxWidth: '88px',
		};
	}
}


const ddStyleNone = {
	menu: (provided, state) => ({
		...provided,
		top: '72%',
		left: 0,
		minWidth: '110%',
		borderTopLeftRadius: 0,
		padding: 5,
	}),
	option: (provided, state) => ({
		...provided,
		//backgroundColor: state.isSelected ? 'black' : 'white',
		color: state.isFocused ? 'white' : 'black',
		backgroundColor: state.isFocused ? 'black' : 'white',
		//padding: 20,

		cursor: 'pointer',

	}),
	container: (provided, state) => ({
		...provided,
		transition: "0.3s",
		cursor: 'text',
	}),
	control: () => ({
		// none of react-select's styles are passed to <Control />
		display: 'flex',
		flexDirection: 'row',
	}),

	indicatorSeparator: () => ({}),
	indicatorsContainer: () => ({}),
	dropdownIndicator: () => ({ marginTop: '8px', }),
	downChevron: () => ({}),

	placeholder: (styles) => ({ ...styles, }),

	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';

		return {
			...provided,
			opacity,
			transition,
			left: 0,
		};
	}
}









class ProductComposer extends React.Component {

	constructor(props) {
		super(props);

		//match props
		//this.props.match.params.productID
		//this.props.match.params.variationID


		//arrDeals
		// > deals
		//	 price
		//	 active (bool)
		//	 retailer
		//	 price
		//	 sale_price
		//	 delivery_price
		//	 delivery_time
		//	 id
		//

		//arrProductImages
		// > fileContent
		// > plainFiles
		// > id

		//arrProductImagesToRemove
		// > id

		//arrAttributes
		// {
		//   key_id
		//   key_name
		//   id
		//   value
		// }

		this.state = {
			arrDeals: [],
			arrAttributes: [],
			arrVariations: [],
			arrProductImages: [],
			arrProductImagesToRemove: [],
			selectedProductImage: 0,
			product_title: "",
			product_description: "",
			browse_images_menu_open: false,
			product_category: -1,
			product_id: 0,
			//gtin: "",
			hasTriedToPublish: false,
			//arrProductDeals: [],

			//for loading product data you're eidting
			isLoading: true,
			isLoadingMore: false,

			//TEMP - Forces variation render mode
			isVariation: false,

		};
	}


	getProduct = () => {

		//get product if we're editing
		if (this.props.match.params?.productID) {

			//get which URL to use to get the product, since variations use a different URL currently
			var _fetchUrl = 'https://ylo.io/api/admin/product/' + this.props.match.params.productID;
			if (this.props.match.params?.variationID) _fetchUrl = 'https://ylo.io/api/admin/product-variation/' + this.props.match.params.variationID;

			return fetch(_fetchUrl, {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
			})
			.then((response) => response.json())
			.then((_json) => {
				console.log(_json);

				//load images
				var _images = [];
				if (_json.attachments) {
					for (var i = 0; i < _json.attachments.length; i++) {
						_images.push({ id: 0, fileContent: {}, plainFiles: {}});
						_images[i].fileContent.content = _json.attachments[i].source;
						_images[i].id = _json.attachments[i].id;
					}
				}


				//load deals
				var _deals = [];
				for (var i = 0; i < _json.deals.length; i++) {
					_deals.push({});
					_deals[i].id = _json.deals[i].id;
					_deals[i].price = _json.deals[i].price;
					_deals[i].retailer = _json.deals[i].retailer;
					_deals[i].delivery_price = _json.deals[i].delivery_price;
					_deals[i].delivery_time = _json.deals[i].delivery_time;
					_deals[i].id = _json.deals[i].id;
					_deals[i].list_id = i;
				}

				//sort everything into their state variables
				this.setState(prevState => ({
					product_title: _json.name,
					product_description: _json.description,
					isLoading: false,
					arrProductImages: _images,
					arrDeals: _deals,
					//arrVariations: _json.variations,
					product_category: _json.category_id,
					arrAttributes: _json.attributes,
				}))

				//set the state variables that are dependant on whether or not this is a variation
				// TODO - probably should do this a better way in future
				if (this.props.match.params?.variationID) {
					this.setState(prevState => ({
						isVariation: true,
						arrVariations: [],
						product_id: this.props.match.params.variationID,
						parent_id: this.props.match.params.productID
					}))
				} else {
					this.setState(prevState => ({
						isVariation: false,
						arrVariations: _json.variations,
						product_id: this.props.match.params.productID,
						parent_id: ""
					}))
				}


			})
			.catch((error) => {
				console.log("[ERROR] LOADING PRODUCT!");
				console.log(error);
			});


		} else {
			this.setState(prevState => ({
				isLoading: false,
				arrDeals: [{ list_id: prevState.arrDeals.length + 1, retailer: "" }],
				arrAttributes: [],
				arrVariations: [],
				arrProductImages: [],
				arrProductImagesToRemove: [],
				selectedProductImage: 0,
				product_title: "",
				product_description: "",
				browse_images_menu_open: false,
				product_category: -1,
				product_id: 0,
				hasTriedToPublish: false,
				isVariation: false
			}))
		}
	}


	componentDidMount() {
		//get catagories
		fetch('https://ylo.io/api/categories', {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
		})
		.then((response) => response.json())
		.then((responseJson) => {
			return responseJson;
		})
		.then((_json) => {
			for (var i = 0; i < _json.length; i++) {
				categories.push({ value: _json[i].id, label: _json[i].name });
			}
		})
		.catch((error) => {
			console.log("[ERROR] LOADING CATEGORY LIST!");
			console.log(error);
		});

		this.getProduct();
	}


	componentDidUpdate(prevProps) {
	  if (
		  this.props.match.params?.variationID !== prevProps.match.params?.variationID ||
		  this.props.match.params?.productID !== prevProps.match.params?.productID
	  ) {
	    this.getProduct();
	  }
	}


	HandleOnProductStateChange = (event) => {

		/* - product state isn't used right now, so these are empty until then
		if (event.value == 1) {

		} else
		if (event.value == 0) {

		}
		*/

		//REMOVE has been selected - TODO - popup warning box?
		if (event.value == -1) {

			//remove all variations of this product
			for (var i = 0; i < this.state.arrVariations.length; i++) {
				//remove product variation
				fetch('https://ylo.io/api/admin/product/' + this.state.arrVariations[i].id, {
					method: 'DELETE',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
				})
				.then((response) => response.json())
				.then((responseJson) => {
					console.log(responseJson);
				})
				.catch((error) => {
					console.log("[ERROR] ERROR REMOVING PRODUCT VARIATION!");
					console.log(error);
				});
            }


			//remove product
			fetch('https://ylo.io/api/admin/product/' + this.state.product_id, {
				method: 'DELETE',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
			})
			.then((response) => response.json())
			.then((responseJson) => {
				console.log(responseJson);

				//exit to product list
				window.location = "/";

			})
			.catch((error) => {
				console.log("[ERROR] ERROR REMOVING PRODUCT!");
				console.log(error);
			});


		} else {

		}
	}



	SaveToCatalogue = () => {

		/////////////////////////////////////////////////////////////////////////////////////////////////////////
		// PACKET 1 - DATA PACKET
		///////////////////////////////////////////////////////////////////////////////////////////////////////
		var _arrDeals = [];
		var _arrAttributes = [];

		//build attributes list
		for (var i = 0; i < this.state.arrAttributes.length; i++) {
			_arrAttributes.push({
				key: this.state.arrAttributes[i].key,
				value: this.state.arrAttributes[i].value,
			});
		}

		//build deals list
		for (var i = 0; i < this.state.arrDeals.length; i++) {
			_arrDeals.push({
				//"price": this.state.arrDeals[i].price,
				"price": this.state.arrDeals[i]?.price ? this.state.arrDeals[i].price : 0,
				"sale_price": this.state.arrDeals[i]?.sale_price ? this.state.arrDeals[i].sale_price : 0,
				"retailer": this.state.arrDeals[i].retailer,
				"active": this.state.arrDeals[i]?.active ? this.state.arrDeals[i].active : false,
				"currency_id": 1, //todo - temp
			});

			//send deal ID if it exists too
			if (this.state.arrDeals[i].id) {
				_arrDeals[i].id = this.state.arrDeals[i].id;
			}
		}

		//build final json
		var json = {
			"name": this.state.product_title,
			"content": this.state.product_description,
			//"gtin": this.state.gtin, - to be replaced with attributes
			"deals": _arrDeals,
			"attributes": this.state.arrAttributes,
			"variations": this.state.arrVariations,
			"category_id": this.state.product_category
		};

		if (this.state.product_id > 0) {
			json.id = this.state.product_id;
		}

		console.log("JSON!");
		console.log(json);



		//SEND TO MOTHERSHIP
		fetch('https://ylo.io/api/admin/save-product', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(json)
		})
		.then((response) => response.json())
		.then((responseJson) => {
			console.log("Save Product returnjson");
			console.log(responseJson);
			//return responseJson;

			//then save attatchments
			if (this.state.arrProductImages.length > 0) {
				if (this.state.product_id) this.SaveAttachments(this.state.product_id);
				else this.SaveAttachments(responseJson.post_id);
			} else {
				//othweise just go straight the edit page
				//todo - consider doing something else if there was an error?
				if (!this.state.product_id) this.props.history.push("/edit-product/" + responseJson.post_id);
            }
		})
		.catch((error) => {
			console.log(error);
		});


	}


	//specify the ID for the product you're saving these attachments for
	SaveAttachments = (id) => {
		var _formData = new FormData();

		// TODO - Make sure we ignore images that don't have a plainfiles file as they;'re old!!!, need to make sure images aren;t gonna get overwritten by the shifted array size?


		//check for images to remove
		if (this.state.arrProductImagesToRemove.length > 0) {

			console.log("removing " + this.state.arrProductImagesToRemove.length + " images from the product");

			for (var i = 0; i < this.state.arrProductImagesToRemove.length; i++) {
				fetch('https://ylo.io/api/admin/attachment/' + this.state.arrProductImagesToRemove[i].id, {
					method: 'DELETE',
				})
				.then((response) => response.json())
				.then((responseJson) => {
					console.log(responseJson);
				})
				.catch((error) => {
					console.log(error);
				});
			}
        }


		//save ONLY the images that need adding to the product

		//only bother to do this if there are any images
		if (this.state.arrProductImages.length > 0) {

			//stinky way of seeing how many attachments we actually send, since getting the formadata length doesn't seem to do it
			var _attachmentsToSave = 0;

			//add attachments to form data
			for (var i = 0; i < this.state.arrProductImages.length; i++) {
				if (this.state.arrProductImages[i].id) continue; //don't bother if this attachment has already been uploaded (and therefore has an ID)
				_formData.append('attachments[]', this.state.arrProductImages[i].plainFiles);
				_attachmentsToSave ++;
			}

			//specify the ID for this product
			_formData.append('id', id);


			//if we still have no images to send (all are already uploaded to the system) - just exit early
			if (_attachmentsToSave <= 0) {
				console.log("None of the " + String(this.state.arrProductImages.length) + " image(s) need saving!");
				return;
			}

			//SEND TO MOTHERSHIP
			fetch('https://ylo.io/api/admin/save-attachments', {
				method: 'POST',
				body: _formData
			})
			.then((response) => response.json())
			.then((responseJson) => {
				console.log(responseJson);
				//go to the edit page now that all is done
				//todo - consider doing something else if there was an error?
				if (!this.state.product_id) this.props.history.push("/edit-product/" + id);
			})
			.catch((error) => {
				console.log(error);
			});

		}
	}


	//for when user clicks publish button, whether or not they can
	HandlePublish = (event) => {

		//save if all required things are in order
		if (this.state.product_title != "" && this.state.product_description != "" && (this.state.arrDeals.length > 0 && this.state.arrDeals[0].active >= 0)) {
			this.SaveToCatalogue();
		}

		this.setState({
			hasTriedToPublish: true,
		});


	}

	HandleProductTitleChange = (event) => {
		this.setState({ product_title: event.target.value });
	}

	HandleProductDescChange = (event) => {
		this.setState({ product_description: event.target.value });
	}

	/* TO BE REPLACED WITH ATTRIBUTE - TODO: REMOVE
	HandleProductGtinChange = (event) => {
		this.setState({ gtin: event.target.value });
	}
	*/

	HandleOnCatagoryChange = (event) => {
		this.setState({ product_category: event.value });
	}



	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// ATTRIBUTES
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	AddAttribute = () => {
		//setArrDeals([...arrDeals, 0]);
		this.setState(prevState => ({
			arrAttributes: [...prevState.arrAttributes, { key_name: "", value: "", }]
		}))

	}

	RemoveAttribute = (id) => {
		this.setState(prevState => ({
			arrAttributes: prevState.arrAttributes.filter((item, i) => i != id)
		}));
	}

	HandleAttributeKeyChange = (id, key, event) => {
		var _arrAttributes = this.state.arrAttributes;
		_arrAttributes[id][key] = event.value;
		this.setState({ arrAttributes: _arrAttributes });
	}

	HandleAttributeValueChange = (id, event) => {
		var _arrAttributes = this.state.arrAttributes;
		_arrAttributes[id].value = event.target.value;
		this.setState({ arrAttributes: _arrAttributes });
	}


	RenderAttributeElements = () => {
		return this.state.arrAttributes.map((attribute, i) => (
			<div className="attribute-container" id={i} key={i.toString()}>
				{/*<input className="input-box attribute-input" type="text" defaultValue="" placeholder="Key..." value={this.state.arrAttributes[i].key} onChange={this.HandleAttributeValueChange.bind(this, i, "key")} />*/}
				<CreatableSelect className="input-box attribute-input" styles={ddStyleNone} options={ddProductAttributeKeys} placeholder="Key..." defaultValue="" value={attribute.key_name != "" && { value: attribute.key_name, label: attribute.key_name }} onChange={this.HandleAttributeKeyChange.bind(this, i, "key_name")}/>
				<input className="input-box attribute-input" type="text" defaultValue="" placeholder="Default Value..." value={attribute.value} onChange={this.HandleAttributeValueChange.bind(this, i)} />
				<div className="attribute-remove-box" onClick={() => this.RemoveAttribute(i)}><div className="attribute-shape-x"/></div>
			</div>
		));
	}

	RenderAttributeElements_variaiton = () => {
		return this.state.arrAttributes.map((attribute, i) => (
			<div className="attribute-container" id={i} key={i.toString()}>
				<div className="input-box attribute-input" style={{ border: 'none', background: 'none', paddingTop: 8, }} > {attribute.key_name } </div>
				<input className="input-box attribute-input" type="text" defaultValue="" placeholder="Default Value..." value={attribute.value} onChange={this.HandleAttributeValueChange.bind(this, i)} />
				<div className="attribute-remove-box attribute-lock-box" onClick={console.log("TODO: make me do things!!")}><div className="attribute-shape-lock" /></div>
			</div>
		));
    }


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// VARIATIONS
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	AddVariation = () => {

		//for sending the parent products ID
		var _json = { id: this.state.product_id };

		//generate new variation
		fetch('https://ylo.io/api/admin/create-product-variation', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(_json)
		})
		.then((response) => response.json())
		.then((responseJson) => {
			console.log(responseJson);

			//if there's no error, we can make a new variation
			// TODO -
			if (!responseJson.error) {
				//setArrDeals([...arrDeals, 0]);
				this.setState(prevState => ({
					arrVariations: [...prevState.arrVariations, { "id": responseJson.id, "name": this.state.product_title, attributes: 0, deals: 0, }]
				}))
			}

		})
		.catch((error) => {
			console.log(error);
		});

	}

	HandleEditVariation = (index) => {
		window.location = "/edit-product/" + this.state.product_id + "/" + this.state.arrVariations[index].id;
	}

	RemoveVariation = (id) => {

		//remove product variation from DB
		fetch('https://ylo.io/api/admin/product/' + this.state.arrVariations[id].id, {
			method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
		})
		.then((response) => response.json())
		.then((responseJson) => {
			console.log(responseJson);

			//remove from list
			this.setState(prevState => ({
				arrVariations: prevState.arrVariations.filter((item, i) => i != id)
			}));

		})
		.catch((error) => {
			console.log("[ERROR] ERROR REMOVING PRODUCT VARIATION!");
			console.log(error);
		});
    }


	HandleOnVariationStateChange = (index, event) => {
		var _arrVariations = this.state.arrVariations;


		if (event.value == 1) {
			_arrVariations[index].active = true;
		} else
		if (event.value == 0) {
			_arrVariations[index].active = false;
		}

		if (event.value == -1) {
			this.RemoveVariation(index);
		} else {
			this.setState({ arrVariations: _arrVariations });
		}
	}


	RenderVariationElements = () => {
		return this.state.arrVariations.map((variation, i) => (
			<div className="deals-box variation-container" id={i} key={i.toString()}>
				{ /* FIRST ROW, PREVIEW IMAGE AND TITLE */ }
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					{ variation.attachments &&
						<div className="variation-image" style={{
							backgroundImage: `url(${variation.attachments[0].source})`,
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat',
							backgroundPosition: '50% 50%'
						}}>
						</div>
					}
					<div>
						<h1>Title</h1>
						{ variation.name }
					</div>
				</div>
				{ /* SECOND ROW, Attributes & Deals */ }
				<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
					<div>
						<h1>Attributes</h1>
						{ variation.attributes.length }
					</div>
					<div>
						<h1>Deals</h1>
						{ variation.deals.length }
					</div>
				</div>

				{ /* LAST ROW, state & Edit button */}
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<div style={{ width: "30%" }}><Select styles={ddStyleVariationState} defaultValue={this.state.product_category} isSearchable={false} options={dealStates} onChange={this.HandleOnVariationStateChange.bind(this, i)}/> </div>
					<button className="button-basic" style={{ width: "119px", height: "32px", bottom: -2, fontSize: 14 }} onClick={this.HandleEditVariation.bind(this, i)}>Edit Variation</button>
				</div>

			</div>
		));
	}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// DEAL LIST STUFF
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	AddDeal = () => {
		//setArrDeals([...arrDeals, 0]);
		this.setState(prevState => ({
			arrDeals: [...prevState.arrDeals, { list_id: prevState.arrDeals.length + 1, retailer: "", active: 1, }]
		}))

	}

	RemoveDeal = (id) => {
		this.setState(prevState => ({
			arrDeals: prevState.arrDeals.filter((item, i) => i != id)
		}));
	}

	HandleDealValueChange = (id, key, event) => {
		var _arrDeals = this.state.arrDeals;
		_arrDeals[id][key] = event.target.value;
		this.setState({ arrDeals: _arrDeals });
	}

	// run when you pick an option from the 'active' dropdown in deals
	HandleOnDealStateChange = (index, event) => {
		var _arrDeals = this.state.arrDeals;

		if (event.value == 1) {
			_arrDeals[index].active = true;
		} else
		if (event.value == 0) {
			_arrDeals[index].active = false;
		}

		if (event.value == -1) {
			this.RemoveDeal(index);
		} else {
			this.setState({ arrDeals: _arrDeals });
		}
	}

	RenderDealList = () => {
		return (
			<div>
				{ /* Deal Top Key Bar */}
				<div className="order-row"><span className="input-label">Deals ({ this.state.arrDeals.length})</span></div>
				<div className="deal-keybar" style={{ display: "flex", flexDirection: "row", paddingRight: "10px", paddingLeft: "20px", paddingTop: "10px" }}>
					<div className="deal-input-box" style={{ width: "170px" }}>
						Active
					</div>
					<div className="deal-input-box" style={{ width: "15%" }}>
						Price
					</div>
					<div className="deal-input-box" style={{ width: "15%" }}>
						Sale Price
					</div>
					<div className="deal-input-box" style={{ width: "10%" }}>
						Stock
					</div>
					<div className="deal-input-box" style={{ width: "15%" }}>
						Delivery Cost
					</div>
					<div className="deal-input-box" style={{ width: "15%" }}>
						Delivery Time
					</div>
					<div className="deal-input-box" style={{ width: "40%" }}>
						Retailer
					</div>
				</div>


				{ /* error warning */ }
				{((this.state.arrDeals.length <= 0 || this.state.arrDeals[0].active < 0) && this.state.hasTriedToPublish) && <div className="input-error-text" style={{width: "100%"}}> * The product must have at least one deal! </div>}


				{this.RenderDealElements()}

				{ /* Add another deal + button */}
				<div className="input-box-border add-deal-box" onClick={() => this.AddDeal()}>
					{/*<div className="ad-list-add-button"> <div className="shape-cross" /> </div>*/}
					<div className="button-basic" style={{ backgroundColor: "#121212", color: "white", height: 32}}> Add Deal </div>
				</div>
			</div>
		);
	}


	RenderDealElements = () => {
		return this.state.arrDeals.map((deal, i) => (
			<div className="deals-box" id={deal.list_id} key={deal.list_id.toString()}>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<div className="deal-input-box" style={{ width: "130px", display: "flex", alignItems: "center" }}>
						<div style={{ fontWeight: "600", width: "130px" }}>
							<Select styles={ddDealStateStyle} isSearchable={false} defaultValue={1} options={dealStates} onChange={this.HandleOnDealStateChange.bind(this, i)} />
						</div>
					</div>
					<div className="deal-input-box" style={{ width: "15%" }}>
						<input className="input-box deal-input" type="text" defaultValue="0.00" value={this.state.arrDeals[i].price} onChange={this.HandleDealValueChange.bind(this, i, "price")} />
					</div>
					<div className="deal-input-box" style={{ width: "15%" }}>
						<input className="input-box deal-input" type="text" defaultValue="0.00" value={this.state.arrDeals[i].sale_price} onChange={this.HandleDealValueChange.bind(this, i, "sale_price")} />
					</div>
					<div className="deal-input-box" style={{ width: "10%" }}>
						<input className="input-box deal-input" type="number" defaultValue="0" value={this.state.arrDeals[i].stock} onChange={this.HandleDealValueChange.bind(this, i, "stock")} />
					</div>
					<div className="deal-input-box" style={{ width: "15%" }}>
						<input className="input-box deal-input" type="text" defaultValue="0.00" value={this.state.arrDeals[i].delivery_price} onChange={this.HandleDealValueChange.bind(this, i, "delivery_price")} />
					</div>
					<div className="deal-input-box" style={{ width: "15%" }}>
						<input className="input-box deal-input" type="number" defaultValue="0" value={this.state.arrDeals[i].delivery_time} onChange={this.HandleDealValueChange.bind(this, i, "delivery_time")} />
					</div>
					<div className="deal-input-box" style={{ width: "40%" }}>
						<input className="input-box deal-input" type="text" name="retailer" value={this.state.arrDeals[i].retailer} onChange={this.HandleDealValueChange.bind(this, i, "retailer")} />
					</div>
					{/*
                    <div>
                        <button className="button-basic" style={{ height: "100%" }} onClick={() => this.RemoveDeal(i)}> Remove Deal </button>
                    </div>
					*/}
				</div>
			</div>
		));
	}




	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// IMAGE LIST STUFF
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	RenderImagePreviews = () => {
		return this.state.arrProductImages.map((deal, i) => (
			<div className="product-image-micro-preview" onClick={this.HandlePreviewClick.bind(this, i)} key={i.toString()} style={this.state.selectedProductImage === i ? { borderColor: "#FFDC15", marginBottom: "0px" } : {}}>
				<div style={{
					overflow: "hidden",
					width: "100%",
					height: "100%",
					display: "flex",
					justifyContent: "center",
					borderRadius: "7px",
					backgroundImage: `url(${this.state.arrProductImages[i].fileContent.content})`,
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: '50% 50%'
				}}>
				</div>
				<div className="product-preview-close-button" onClick={this.HandleImageRemove.bind(this, i)}> <div className="shape-x" /> </div>
			</div>
		));
	}


	HandlePreviewClick = (index) => {
		this.setState(prevState => ({
			selectedProductImage: index,
		}));
	}


	HandleImageRemove = (index) => {
		//if the image has an ID (so it's been uploaded to the db) we need to add it to a list to be removed on the backend
		if (this.state.arrProductImages[index].id) {

			var _imageToRemove = {};
			_imageToRemove.id = this.state.arrProductImages[index].id;

			this.setState(prevState => ({
				arrProductImagesToRemove: [...prevState.arrProductImagesToRemove, _imageToRemove],
			}));
		}

		//remove the image from our list of images
		this.setState(prevState => ({
			//arrProductImages: prevState.arrProductImages.filter((item, i) => i != index),
			arrProductImages: prevState.arrProductImages.filter((item, i) => i != index),
			selectedProductImage: -1
		}));
	}


	HandleAddImage = (data, plainFiles, event) => {

		var _image = {};

		_image.fileContent = data[0];
		_image.plainFiles = plainFiles[0];

		if (data[0].content) {
			this.setState(prevState => ({
				//arrProductImages: [...prevState.arrProductImages, data[0]],
				arrProductImages: [...prevState.arrProductImages, _image],
			}));
		}

		console.log(_image);

	}






	render() {

		let mainProductImage = (<div className="product-image"></div>);

		//check if the product has all the required inputs filled in
		var canPublish = false;
		if (this.state.product_title != "" && this.state.product_description != "" && this.state.product_category && this.state.arrDeals.length > 0) {
			canPublish = true;
		}

		if (this.state.arrProductImages.length > 0 && this.state.selectedProductImage >= 0 && this.state.selectedProductImage < this.state.arrProductImages.length && this.state.arrProductImages[this.state.selectedProductImage].fileContent) {
			mainProductImage = (
				<div className="product-image" style={{
					backgroundImage: `url(${this.state.arrProductImages[this.state.selectedProductImage].fileContent.content})`,
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: '50% 50%'
				}}></div>
			);
		}


		// Get product page title, depending on if you're creating, editing or editing a variation
		var _title;

		if (this.state.isVariation) {
			_title = (
				<h1 style={{ fontSize: "40px" }}>
					Edit Product Variation
				</h1>
			);
		} else {
			_title = (
				<h1 style={{ fontSize: "40px" }}>
					{this.state.product_id ? "Edit Product" : "Create Product"}
				</h1>
			);
        }

		return (
			<div className="app-base" style={{display: 'flex', justifyContent: 'center'}}>
			<div style={{ width: "100%", maxWidth: "1280px" }}>
				{this.state.isLoading ? (
					<></>
				) : (
					<>
					{/* TOP BAR */}
					{ this.state.isVariation &&
						<Link className="button-basic button-black" to={"/edit-product/" + this.state.parent_id} style={{ color: "white", width: 140, height: 34, marginBottom: 30, textDecoration: "none" }}> {"←"} Return to Parent </Link>
					}

					{ _title }
					{/* PRODUCT ID (if editing) */}
					{(this.state.product_id > 0 && !this.state.isVariation) && <div style={{ fontSize: "15px", color: "#969696", fontWeight: "700", marginTop: "6px" }}> ID  {this.state.product_id} </div>}

					{ /* SHOW VARIATION ID AND PARENT ID - when editing variation */}
					{this.state.isVariation &&
						<div style={{display: 'flex', flexDirection: 'row', width: '30%'}}>
							<div style={{ fontSize: "15px", color: "#969696", fontWeight: "700", marginTop: "6px", width: '50%' }}> Parent ID:  {this.state.parent_id} </div>
							<div style={{ fontSize: "15px", color: "#969696", fontWeight: "700", marginTop: "6px", width: '50%' }}> Variation ID:  {this.state.product_id} </div>
						</div>
					}

					<div style={{ marginBottom: "30px" }} />




					{ !this.state.isVariation &&
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '60px' }}>
							{/* category */}
							<div style={{ marginRight: "20px", color: "#808080", fontWeight: "500" }}> Category: </div>
							<div style={{ marginRight: "60px", fontWeight: "500", width: "300px" }}>
							<Select styles={dropdownStyle} options={categories} value={categories[this.state.product_category]} onChange={this.HandleOnCatagoryChange.bind(this)} />
								{(this.state.hasTriedToPublish && !this.state.product_category) && <div className="input-error-text" style={{ width: "100%" }}> * Category is required! </div>}
							</div>


							{/* product tags */}
							<div style={{ marginRight: "20px", color: "#808080", fontWeight: "500" }}> Tags: </div>
							<div style={{ marginRight: "60px", fontWeight: "500", minWidth: "300px" }}>
								<Select isMulti styles={dropdownStyle} options={productTags} />
							</div>
						</div>
					}

					<div className="order-row" style={{ justifyContent: "space-between", marginBottom: "30px" }}>
						{/* LEFT SIDE */}
						<div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>

							{ /* TITLE INPUT AREA */ }
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
								<span className="input-label">Title</span>

								{(this.state.product_title === "" && this.state.hasTriedToPublish) && <div className="input-error-text"> * Title is required! </div>}

								{ /* INHERIT FROM PARENT CHECKBOX */ }
								{this.state.isVariation &&
									<div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
										<span style={{ fontSize: 13, fontWeight: 500, marginRight: 5 }}>Inherit from Parent</span>
										<div className="checkcircle"> </div>
									</div>
								}

							</div>

							<div style={{ marginBottom: 24 }}>
								<input className="input-box"
									style={(this.state.product_title === "" && this.state.hasTriedToPublish) ? { borderColor: "#ff6600" } : {}}
									type="text" value={this.state.product_title}
									onChange={this.HandleProductTitleChange} />
							</div>


							{ /* CONTENT INPUT AREA */ }
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
								<span className="input-label">Content</span>

								{(this.state.product_description === "" && this.state.hasTriedToPublish) && <div className="input-error-text"> * Content is required! </div>}

								{ /* INHERIT FROM PARENT CHECKBOX */}
								{this.state.isVariation &&
									<div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
										<span style={{ fontSize: 13, fontWeight: 500, marginRight: 5 }}>Inherit from Parent</span>
										<div className="checkcircle"> </div>
									</div>
								}

							</div>


							<textarea className="input-box input-box--textarea"
								style={(this.state.product_description === "" && this.state.hasTriedToPublish) ? { borderColor: "#ff6600" } : {}}
								type="textarea" value={this.state.product_description}
								onChange={this.HandleProductDescChange}
							/>

							{ /* GTIN INPUT AREA }
							<div style={{ marginTop: "28px", marginBottom: "20px", display: 'flex', flexDirection: 'column'}}>
							<span className="input-label"> GTIN </span>
								<input className="input-box" style={{ marginBottom: 24, width: "50%" }} type="text" value={this.state.gtin} onChange={this.HandleProductGtinChange} />
							</div>
							*/}

						</div>

						{/* RIGHT SIDE */}
						<div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>

							{ /* IMAGE PREVIEW */}
							<span className="input-label">Media (Images / Video)</span>
							{mainProductImage}

							{/* IMAGE ALBUM */}
							<div className="attachment-previews">
								{this.RenderImagePreviews()}
								<div className="product-image-micro-preview"> <div className="ad-list-add-button" style={{ position: 'absolute' }} >  <div className="shape-cross" /> </div> <FilePicker processFiles={this.HandleAddImage} > </FilePicker> </div>
							</div>
						</div>
					</div>


					<div className="order-row" style={{ justifyContent: "space-between", marginBottom: "30px" }}>

						{/* ATTRIBUTES */}
						<div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
							<span className="input-label">Attributes ({this.state.arrAttributes.length})</span>
							{ !this.state.isVariation && this.RenderAttributeElements() }
							{ this.state.isVariation && this.RenderAttributeElements_variaiton() }
							{ /* Add another attribute button */}
							{ !this.state.isVariation &&
								<div className="input-box-border add-deal-box" onClick={() => this.AddAttribute()}>
									<div className="button-basic" style={{ backgroundColor: "#121212", color: "white", height: 32 }}> Add Attribute </div>
								</div>
							}

						</div>

						{/* VARIATIONS */}
						{!this.state.isVariation &&
							<div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
								<span className="input-label">Variations ({this.state.arrVariations.length})</span>
								{this.RenderVariationElements()}

								{ /* Add another variation button */}
								{ this.state.product_id ?

									<div className="input-box-border add-deal-box" onClick={() => this.AddVariation()}>
										<div className="button-basic" style={{ backgroundColor: "#121212", color: "white", height: 32 }}> Add Variation </div>
									</div>
									:
									<div className="input-box-border add-deal-box">
										<div style={{color: '#888888', fontSize: 16}}> Save Draft / Publish in order to add variations. </div>
									</div>

								}
							</div>
						}
					</div>

					{this.RenderDealList()}


					{ /* page top bar */}
					{!this.state.isVariation &&
						<div>
							{ this.state.product_id ?
								<div className="global-pagebar">
									{ /* EDIT MODE  options */}
									<div style={{ width: "50%" }}><Select styles={dropdownStyle} isSearchable={false} options={productState} onChange={this.HandleOnProductStateChange.bind(this)}/> </div>
									<button className="button-basic" style={{ width: "90px", height: "90%" }} onClick={this.HandlePublish}> Save </button>
								</div>
								:
								<div className="global-pagebar">
									{ /* CREATE MODE  options */}
									<div style={{cursor: "pointer", userSelect: "none" }}> <u> Save Draft </u> </div>
									<button className="button-basic" style={{ width: "90px", height: "90%" }} onClick={this.HandlePublish}> Publish </button>
								</div>
							}
						</div>
					}

					{ /* EDIT VARIATION MODE  options */}
					{this.state.isVariation &&
						<div className="global-pagebar">

							<div style={{ width: "40%" }}><Select styles={ddStyleVariationState} isSearchable={false} options={ddProductVariationState} /> </div>
							<button className="button-basic" style={{ width: "90px", height: "90%" }} onClick={this.HandlePublish}> Save </button>
						</div>
					}

					</>
				)}
			</div>
			</div>
		)
	}
}


export default ProductComposer;
